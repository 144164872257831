import React, {
  FC,
  MutableRefObject,
  useRef,
  useCallback,
} from 'react';
import { useSwipeable } from 'react-swipeable';
import { cn } from '@/lib/classNames';
import { useOnKeyup } from '@/hooks/useOnKeyup';
import styles from './PlatformSidebar.module.scss';
import { TRANSITION_DURATION } from './constants';

interface Props {
  closeSidebar: () => void;
  isSidebarOpen: boolean;
  shouldCloseOnSwipe?: boolean;
  classNames?: string;
  sidebarClassName?: string;
  mode?: PlatformSidebarMode;
  sidebarVhHeight?: PlatformSidebarHeight;
  dataQa?: string;
}

export enum PlatformSidebarMode {
  Aside = 'aside',
  Bottom = 'bottom',
}

export enum PlatformSidebarHeight {
  Vh10 = 10,
  Vh20 = 20,
  Vh30 = 30,
  Vh40 = 40,
  Vh50 = 50,
  Vh60 = 60,
  Vh70 = 70,
  Vh80 = 80,
  Vh90 = 90,
  Vh100 = 100,
  Auto = 'auto',
}

export const PlatformSidebar: FC<Props> = ({
  closeSidebar,
  isSidebarOpen,
  shouldCloseOnSwipe = true,
  children,
  classNames,
  sidebarClassName,
  mode = PlatformSidebarMode.Aside,
  sidebarVhHeight = PlatformSidebarHeight.Vh80,
  dataQa,
}) => {
  const wrapperRef: MutableRefObject<
    HTMLDivElement | null
  > = useRef<HTMLDivElement | null>(null);
  const overlayRef: MutableRefObject<
    HTMLDivElement | null
  > = useRef<HTMLDivElement | null>(null);

  // Handle swipes on sidebar
  const handlers = useSwipeable(
    shouldCloseOnSwipe
      ? {
        onSwipedDown: () => {
          const scrollTop = wrapperRef.current?.scrollTop || 0;

          if (scrollTop > 0) {
            return;
          }

          closeSidebar();
        },
        onSwipedRight: () => closeSidebar(),
      }
      : {},
  );

  useOnKeyup({
    keyName: 'Escape',
    callback: closeSidebar,
    condition: isSidebarOpen,
  });

  const handleSidebarClose = useCallback(() => {
    wrapperRef.current?.classList.add(styles.closeModal);
    overlayRef.current?.classList.add(styles.closeOverlay);

    setTimeout(() => {
      wrapperRef.current?.classList.remove(styles.closeModal);
      overlayRef.current?.classList.remove(styles.closeOverlay);

      closeSidebar();
    }, TRANSITION_DURATION);
  }, [closeSidebar]);

  const isBottomSidebar = mode === PlatformSidebarMode.Bottom;

  return (
    <>
      <div
        data-qa={dataQa}
        className={cn(
          styles.sidebarOverlay,
          {
            [styles.overlayActive]: isSidebarOpen,
            [styles.sidebarOverlayBottom]: mode === PlatformSidebarMode.Bottom,
          },
          classNames,
        )}
        ref={overlayRef}
        onClick={handleSidebarClose}
        role="button"
        tabIndex={0}
        aria-label="Sidebar overlay"
        onKeyDown={(event) => {
          if (event.key === ' ') {
            handleSidebarClose();
          }
        }}
      />
      <div
        {...handlers}
        ref={(element) => {
          wrapperRef.current = element;

          handlers.ref(element);
        }}
        className={cn(
          styles.sidebarWrapper,
          {
            [styles.leftSidebarWrapper]: !isBottomSidebar,
            [styles.bottomSidebarWrapper]: isBottomSidebar,
            [styles.active]: isSidebarOpen,
            [styles[`bottomSidebarHeight-${sidebarVhHeight}`]]: isBottomSidebar,
          },
          sidebarClassName,
        )}
      >
        {children}
      </div>
    </>
  );
};
